<template>
  <div>
    <ConnectWallet />
    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1139098004771586068/searching-contacts-2021-09-24-02-40-03-utc.jpg');
        "
      >
        <div class="text-center">
          <h2 class="font-sans text-2xl font-bold sm:text-6xl text-gray-200">
            Global Headquarters
          </h2>

          <h2
            class="font-sans text-xl font-bold sm:text-3xl text-gray-200 mt-12"
          >
            AI Virtual
          </h2>

          <h2
            class="font-sans text-xl font-bold sm:text-2xl text-gray-200 mt-5 max-w-screen-sm"
          >
            6/55 Chan Space office building, 1st. Floor, Room 5A, Nang Linchi
            Road, Thung Maha Mek Sub-district, Sathon District, Bangkok 10120
          </h2>

          <div class="mt-10">
            <i class="fa fa-envelope text-blue-500" style="font-size: 56px"></i>

            <h2
              class="font-sans text-xl font-bold sm:text-3xl text-gray-200 mt-1"
            >
              hello@aivirtual.com
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prompt from "@/api/prompt";
import ConnectWallet from "@/components/ConnectWallet.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],

      ref: this.$route.params.ref,
    };
  },
  components: {
    ConnectWallet,
  },
  computed: mapGetters(["getToken"]),

  methods: {
    async getNewPrompts() {
      await Prompt.trendPrompts()
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getNewPrompts();
  },
};
</script>
